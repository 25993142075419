// Bootstrap functions and mixins
@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_mixins.scss";

// Progress loader
@import "nprogress/nprogress.css";

// Global styles
@import "globals";

// Deprecated styles
@import "deprecated";

// Bootstrap framework
@import "bootstrap/bootstrap";

// Overrides
@import "overrides";

// Miscellaneous
@import "calendar-heatmap.min.css";

@import "node_modules/react-toastify/scss/main.scss";
